<template>
  <div class="vehicle-type-view-page container" v-if="vehicleType">
    <h1>Edit vehicle type</h1>
    <hr />

    <form>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" :disabled="!currentAccount.hasPermission('vehicle-types.edit')" />
      </div>

      <div class="mb-3">
        <label for="color" class="form-label">Color</label>
        <input type="color" class="form-control" v-model="color" />
      </div>

      <button type="button" class="btn btn-primary" :disabled="!currentAccount.hasPermission('vehicle-types.edit')" @click="save">
        Save
      </button>
      <button type="button" class="btn btn-danger ms-2" :disabled="!currentAccount.hasPermission('vehicle-types.remove')" @click="remove">
        Delete
      </button>

      <span class="ms-3 text-muted">
        <template v-if="vehicleType.updatedAt">
          Updated by <b>{{ vehicleType.updatedByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(vehicleType.updatedAt)) }}</b>
        </template>
        <template v-else>
          Created by <b class="font-weight-bold">{{ vehicleType.createdByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(vehicleType.createdAt)) }}</b>
        </template>
      </span>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTimeFormatter } from '@/utils';
import { findVehicleType, updateVehicleType, deleteVehicleType } from '@/api';

export default {
  name: 'VehicleTypeEditPage',
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    name: '',
    color: '',

    vehicleType: null,

    dateTimeFormatter,
  }),
  methods: {
    async update(withReset = true) {
      try {
        if (withReset) {
          this.vehicleType = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findVehicleType(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.vehicleType = response?.data?.vehicleType;
        if (!this.vehicleType) {
          throw new Error('Vehicle type not found');
        }

        this.name = this.vehicleType.name;
        this.color = this.vehicleType.color;
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    async save() {
      try {
        const data = {};

        if (this.name && this.name !== this.vehicleType.name) {
          data.name = this.name;
        }

        if (this.color && this.color !== this.vehicleType.color) {
          data.color = this.color;
        }

        const response = await updateVehicleType(this.$route.params.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle type was updated.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'vehicle-types' }), 2000);
        this.update(false);
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    async remove() {
      try {
        const response = await deleteVehicleType(this.$route.params.id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$router.push({ name: 'vehicle-types' });
        this.$toast.success('Vehicle type was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
  activated() {
    this.update();
  },
  watch: {
    '$route.params.id': {
      handler(value) {
        this.update();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.vehicle-type-view-page {
  padding: 2em 0;
}

.control input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.control button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
